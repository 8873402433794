<!--<script lang="ts">-->
<!--import { defineComponent, ref, onMounted } from 'vue';-->
<!--import LoadingPage from '~/components/LoadingPage.vue';-->

<!--export default defineComponent({-->
<!--    components: {-->
<!--        LoadingPage,-->
<!--    },-->
<!--    setup() {-->
<!--        const isReloading = ref(true);-->
<!--        const percentage = ref(2);-->

<!--        onMounted(() => {-->
<!--            let intervalId = setInterval(() => {-->
<!--                if (percentage.value < 98) {-->
<!--                    percentage.value += 3;-->
<!--                } else {-->
<!--                    clearInterval(intervalId);-->
<!--                    isReloading.value = false;-->
<!--                }-->
<!--            }, 50);-->
<!--        });-->

<!--        return { isReloading, percentage };-->
<!--    },-->
<!--});-->
<!--</script>-->

<template>
    <div>
<!--        <LoadingPage v-if="isReloading" :percentage="percentage" />-->
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>
