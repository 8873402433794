export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"application-name","content":"MVE"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"keywords","content":"MVE, cyber, smart-home, monaco, blockchain, cloud, storage, server, security, domotique, domotic, audit, passi, security, homologuation, hebergement, souverain"},{"name":"robots","content":"index, follow"},{"name":"language","content":"fr"},{"http-equiv":"Cache-Control","content":"no-cache, no-store, must-revalidate"},{"http-equiv":"Expires","content":"0"},{"http-equiv":"X-UA-Compatible","content":"IE=edge"},{"property":"og:title","content":"MVE - Smart Home et Cyber-securité à Monaco"},{"property":"description","content":"Découvrez MVE, le leader de la cyber-sécurité, du stockage en cloud, de la domotique et de la blockchain à Monaco. Nous offrons des solutions de sécurité informatique haut de gamme."},{"property":"og:description","content":"Découvrez MVE, le leader de la cyber-sécurité, du stockage en cloud, de la domotique et de la blockchain à Monaco. Nous offrons des solutions de sécurité informatique haut de gamme."},{"property":"og:type","content":"website"},{"property":"og:url","content":"http://mve.mc"},{"property":"og:image","content":"https://mve.mc/assets/logo.webp"},{"property":"og:locale","content":"fr_FR"},{"property":"og:site_name","content":"MVE"}],"link":[],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"fr"},"title":"MVE","charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const devPagesDir = null

export const devRootDir = null