import { default as indexaUClWzF09lMeta } from "/usr/src/nuxt-app/pages/about-us/index.vue?macro=true";
import { default as indexhXFg3sYFeRMeta } from "/usr/src/nuxt-app/pages/blockchain/index.vue?macro=true";
import { default as _91id_93GbleYdb99GMeta } from "/usr/src/nuxt-app/pages/careers/contact/[id].vue?macro=true";
import { default as index5e65Opqx57Meta } from "/usr/src/nuxt-app/pages/careers/index.vue?macro=true";
import { default as indexxQqSN0xApAMeta } from "/usr/src/nuxt-app/pages/certifications/index.vue?macro=true";
import { default as indexRBL8TFKg6zMeta } from "/usr/src/nuxt-app/pages/cloud/index.vue?macro=true";
import { default as indexOggkLh9ZdlMeta } from "/usr/src/nuxt-app/pages/cookies-policy/index.vue?macro=true";
import { default as indexc3K3MTvFryMeta } from "/usr/src/nuxt-app/pages/cybersecurity/index.vue?macro=true";
import { default as indexqXyG3csxOHMeta } from "/usr/src/nuxt-app/pages/devops/index.vue?macro=true";
import { default as indexlL5oqNAJvXMeta } from "/usr/src/nuxt-app/pages/fond-bleu/index.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as index1JgM9FMaqNMeta } from "/usr/src/nuxt-app/pages/legal/index.vue?macro=true";
import { default as indexQUlGKJ5NgCMeta } from "/usr/src/nuxt-app/pages/security/index.vue?macro=true";
import { default as index7XgwCHpLSRMeta } from "/usr/src/nuxt-app/pages/smart-home/index.vue?macro=true";
import { default as indexcF9BcfoxGGMeta } from "/usr/src/nuxt-app/pages/support/index.vue?macro=true";
export default [
  {
    name: indexaUClWzF09lMeta?.name ?? "about-us___en-US",
    path: indexaUClWzF09lMeta?.path ?? "/en-US/about-us",
    meta: indexaUClWzF09lMeta || {},
    alias: indexaUClWzF09lMeta?.alias || [],
    redirect: indexaUClWzF09lMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexaUClWzF09lMeta?.name ?? "about-us___fr-FR",
    path: indexaUClWzF09lMeta?.path ?? "/about-us",
    meta: indexaUClWzF09lMeta || {},
    alias: indexaUClWzF09lMeta?.alias || [],
    redirect: indexaUClWzF09lMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexaUClWzF09lMeta?.name ?? "about-us___it-IT",
    path: indexaUClWzF09lMeta?.path ?? "/it-IT/about-us",
    meta: indexaUClWzF09lMeta || {},
    alias: indexaUClWzF09lMeta?.alias || [],
    redirect: indexaUClWzF09lMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexhXFg3sYFeRMeta?.name ?? "blockchain___en-US",
    path: indexhXFg3sYFeRMeta?.path ?? "/en-US/blockchain",
    meta: indexhXFg3sYFeRMeta || {},
    alias: indexhXFg3sYFeRMeta?.alias || [],
    redirect: indexhXFg3sYFeRMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/blockchain/index.vue").then(m => m.default || m)
  },
  {
    name: indexhXFg3sYFeRMeta?.name ?? "blockchain___fr-FR",
    path: indexhXFg3sYFeRMeta?.path ?? "/blockchain",
    meta: indexhXFg3sYFeRMeta || {},
    alias: indexhXFg3sYFeRMeta?.alias || [],
    redirect: indexhXFg3sYFeRMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/blockchain/index.vue").then(m => m.default || m)
  },
  {
    name: indexhXFg3sYFeRMeta?.name ?? "blockchain___it-IT",
    path: indexhXFg3sYFeRMeta?.path ?? "/it-IT/blockchain",
    meta: indexhXFg3sYFeRMeta || {},
    alias: indexhXFg3sYFeRMeta?.alias || [],
    redirect: indexhXFg3sYFeRMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/blockchain/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GbleYdb99GMeta?.name ?? "careers-contact-id___en-US",
    path: _91id_93GbleYdb99GMeta?.path ?? "/en-US/careers/contact/:id()",
    meta: _91id_93GbleYdb99GMeta || {},
    alias: _91id_93GbleYdb99GMeta?.alias || [],
    redirect: _91id_93GbleYdb99GMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/careers/contact/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GbleYdb99GMeta?.name ?? "careers-contact-id___fr-FR",
    path: _91id_93GbleYdb99GMeta?.path ?? "/careers/contact/:id()",
    meta: _91id_93GbleYdb99GMeta || {},
    alias: _91id_93GbleYdb99GMeta?.alias || [],
    redirect: _91id_93GbleYdb99GMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/careers/contact/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GbleYdb99GMeta?.name ?? "careers-contact-id___it-IT",
    path: _91id_93GbleYdb99GMeta?.path ?? "/it-IT/careers/contact/:id()",
    meta: _91id_93GbleYdb99GMeta || {},
    alias: _91id_93GbleYdb99GMeta?.alias || [],
    redirect: _91id_93GbleYdb99GMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/careers/contact/[id].vue").then(m => m.default || m)
  },
  {
    name: index5e65Opqx57Meta?.name ?? "careers___en-US",
    path: index5e65Opqx57Meta?.path ?? "/en-US/careers",
    meta: index5e65Opqx57Meta || {},
    alias: index5e65Opqx57Meta?.alias || [],
    redirect: index5e65Opqx57Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index5e65Opqx57Meta?.name ?? "careers___fr-FR",
    path: index5e65Opqx57Meta?.path ?? "/careers",
    meta: index5e65Opqx57Meta || {},
    alias: index5e65Opqx57Meta?.alias || [],
    redirect: index5e65Opqx57Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index5e65Opqx57Meta?.name ?? "careers___it-IT",
    path: index5e65Opqx57Meta?.path ?? "/it-IT/careers",
    meta: index5e65Opqx57Meta || {},
    alias: index5e65Opqx57Meta?.alias || [],
    redirect: index5e65Opqx57Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexxQqSN0xApAMeta?.name ?? "certifications___en-US",
    path: indexxQqSN0xApAMeta?.path ?? "/en-US/certifications",
    meta: indexxQqSN0xApAMeta || {},
    alias: indexxQqSN0xApAMeta?.alias || [],
    redirect: indexxQqSN0xApAMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/certifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexxQqSN0xApAMeta?.name ?? "certifications___fr-FR",
    path: indexxQqSN0xApAMeta?.path ?? "/certifications",
    meta: indexxQqSN0xApAMeta || {},
    alias: indexxQqSN0xApAMeta?.alias || [],
    redirect: indexxQqSN0xApAMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/certifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexxQqSN0xApAMeta?.name ?? "certifications___it-IT",
    path: indexxQqSN0xApAMeta?.path ?? "/it-IT/certifications",
    meta: indexxQqSN0xApAMeta || {},
    alias: indexxQqSN0xApAMeta?.alias || [],
    redirect: indexxQqSN0xApAMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/certifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexRBL8TFKg6zMeta?.name ?? "cloud___en-US",
    path: indexRBL8TFKg6zMeta?.path ?? "/en-US/cloud",
    meta: indexRBL8TFKg6zMeta || {},
    alias: indexRBL8TFKg6zMeta?.alias || [],
    redirect: indexRBL8TFKg6zMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/cloud/index.vue").then(m => m.default || m)
  },
  {
    name: indexRBL8TFKg6zMeta?.name ?? "cloud___fr-FR",
    path: indexRBL8TFKg6zMeta?.path ?? "/cloud",
    meta: indexRBL8TFKg6zMeta || {},
    alias: indexRBL8TFKg6zMeta?.alias || [],
    redirect: indexRBL8TFKg6zMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/cloud/index.vue").then(m => m.default || m)
  },
  {
    name: indexRBL8TFKg6zMeta?.name ?? "cloud___it-IT",
    path: indexRBL8TFKg6zMeta?.path ?? "/it-IT/cloud",
    meta: indexRBL8TFKg6zMeta || {},
    alias: indexRBL8TFKg6zMeta?.alias || [],
    redirect: indexRBL8TFKg6zMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/cloud/index.vue").then(m => m.default || m)
  },
  {
    name: indexOggkLh9ZdlMeta?.name ?? "cookies-policy___en-US",
    path: indexOggkLh9ZdlMeta?.path ?? "/en-US/cookies-policy",
    meta: indexOggkLh9ZdlMeta || {},
    alias: indexOggkLh9ZdlMeta?.alias || [],
    redirect: indexOggkLh9ZdlMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/cookies-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexOggkLh9ZdlMeta?.name ?? "cookies-policy___fr-FR",
    path: indexOggkLh9ZdlMeta?.path ?? "/cookies-policy",
    meta: indexOggkLh9ZdlMeta || {},
    alias: indexOggkLh9ZdlMeta?.alias || [],
    redirect: indexOggkLh9ZdlMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/cookies-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexOggkLh9ZdlMeta?.name ?? "cookies-policy___it-IT",
    path: indexOggkLh9ZdlMeta?.path ?? "/it-IT/cookies-policy",
    meta: indexOggkLh9ZdlMeta || {},
    alias: indexOggkLh9ZdlMeta?.alias || [],
    redirect: indexOggkLh9ZdlMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/cookies-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexc3K3MTvFryMeta?.name ?? "cybersecurity___en-US",
    path: indexc3K3MTvFryMeta?.path ?? "/en-US/cybersecurity",
    meta: indexc3K3MTvFryMeta || {},
    alias: indexc3K3MTvFryMeta?.alias || [],
    redirect: indexc3K3MTvFryMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/cybersecurity/index.vue").then(m => m.default || m)
  },
  {
    name: indexc3K3MTvFryMeta?.name ?? "cybersecurity___fr-FR",
    path: indexc3K3MTvFryMeta?.path ?? "/cybersecurity",
    meta: indexc3K3MTvFryMeta || {},
    alias: indexc3K3MTvFryMeta?.alias || [],
    redirect: indexc3K3MTvFryMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/cybersecurity/index.vue").then(m => m.default || m)
  },
  {
    name: indexc3K3MTvFryMeta?.name ?? "cybersecurity___it-IT",
    path: indexc3K3MTvFryMeta?.path ?? "/it-IT/cybersecurity",
    meta: indexc3K3MTvFryMeta || {},
    alias: indexc3K3MTvFryMeta?.alias || [],
    redirect: indexc3K3MTvFryMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/cybersecurity/index.vue").then(m => m.default || m)
  },
  {
    name: indexqXyG3csxOHMeta?.name ?? "devops___en-US",
    path: indexqXyG3csxOHMeta?.path ?? "/en-US/devops",
    meta: indexqXyG3csxOHMeta || {},
    alias: indexqXyG3csxOHMeta?.alias || [],
    redirect: indexqXyG3csxOHMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/devops/index.vue").then(m => m.default || m)
  },
  {
    name: indexqXyG3csxOHMeta?.name ?? "devops___fr-FR",
    path: indexqXyG3csxOHMeta?.path ?? "/devops",
    meta: indexqXyG3csxOHMeta || {},
    alias: indexqXyG3csxOHMeta?.alias || [],
    redirect: indexqXyG3csxOHMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/devops/index.vue").then(m => m.default || m)
  },
  {
    name: indexqXyG3csxOHMeta?.name ?? "devops___it-IT",
    path: indexqXyG3csxOHMeta?.path ?? "/it-IT/devops",
    meta: indexqXyG3csxOHMeta || {},
    alias: indexqXyG3csxOHMeta?.alias || [],
    redirect: indexqXyG3csxOHMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/devops/index.vue").then(m => m.default || m)
  },
  {
    name: indexlL5oqNAJvXMeta?.name ?? "fond-bleu___en-US",
    path: indexlL5oqNAJvXMeta?.path ?? "/en-US/fond-bleu",
    meta: indexlL5oqNAJvXMeta || {},
    alias: indexlL5oqNAJvXMeta?.alias || [],
    redirect: indexlL5oqNAJvXMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/fond-bleu/index.vue").then(m => m.default || m)
  },
  {
    name: indexlL5oqNAJvXMeta?.name ?? "fond-bleu___fr-FR",
    path: indexlL5oqNAJvXMeta?.path ?? "/fond-bleu",
    meta: indexlL5oqNAJvXMeta || {},
    alias: indexlL5oqNAJvXMeta?.alias || [],
    redirect: indexlL5oqNAJvXMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/fond-bleu/index.vue").then(m => m.default || m)
  },
  {
    name: indexlL5oqNAJvXMeta?.name ?? "fond-bleu___it-IT",
    path: indexlL5oqNAJvXMeta?.path ?? "/it-IT/fond-bleu",
    meta: indexlL5oqNAJvXMeta || {},
    alias: indexlL5oqNAJvXMeta?.alias || [],
    redirect: indexlL5oqNAJvXMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/fond-bleu/index.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___en-US",
    path: indexAoBMx6PnC1Meta?.path ?? "/en-US",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___fr-FR",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___it-IT",
    path: indexAoBMx6PnC1Meta?.path ?? "/it-IT",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index1JgM9FMaqNMeta?.name ?? "legal___en-US",
    path: index1JgM9FMaqNMeta?.path ?? "/en-US/legal",
    meta: index1JgM9FMaqNMeta || {},
    alias: index1JgM9FMaqNMeta?.alias || [],
    redirect: index1JgM9FMaqNMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: index1JgM9FMaqNMeta?.name ?? "legal___fr-FR",
    path: index1JgM9FMaqNMeta?.path ?? "/legal",
    meta: index1JgM9FMaqNMeta || {},
    alias: index1JgM9FMaqNMeta?.alias || [],
    redirect: index1JgM9FMaqNMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: index1JgM9FMaqNMeta?.name ?? "legal___it-IT",
    path: index1JgM9FMaqNMeta?.path ?? "/it-IT/legal",
    meta: index1JgM9FMaqNMeta || {},
    alias: index1JgM9FMaqNMeta?.alias || [],
    redirect: index1JgM9FMaqNMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: indexQUlGKJ5NgCMeta?.name ?? "security___en-US",
    path: indexQUlGKJ5NgCMeta?.path ?? "/en-US/security",
    meta: indexQUlGKJ5NgCMeta || {},
    alias: indexQUlGKJ5NgCMeta?.alias || [],
    redirect: indexQUlGKJ5NgCMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexQUlGKJ5NgCMeta?.name ?? "security___fr-FR",
    path: indexQUlGKJ5NgCMeta?.path ?? "/security",
    meta: indexQUlGKJ5NgCMeta || {},
    alias: indexQUlGKJ5NgCMeta?.alias || [],
    redirect: indexQUlGKJ5NgCMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexQUlGKJ5NgCMeta?.name ?? "security___it-IT",
    path: indexQUlGKJ5NgCMeta?.path ?? "/it-IT/security",
    meta: indexQUlGKJ5NgCMeta || {},
    alias: indexQUlGKJ5NgCMeta?.alias || [],
    redirect: indexQUlGKJ5NgCMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/security/index.vue").then(m => m.default || m)
  },
  {
    name: index7XgwCHpLSRMeta?.name ?? "smart-home___en-US",
    path: index7XgwCHpLSRMeta?.path ?? "/en-US/smart-home",
    meta: index7XgwCHpLSRMeta || {},
    alias: index7XgwCHpLSRMeta?.alias || [],
    redirect: index7XgwCHpLSRMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/smart-home/index.vue").then(m => m.default || m)
  },
  {
    name: index7XgwCHpLSRMeta?.name ?? "smart-home___fr-FR",
    path: index7XgwCHpLSRMeta?.path ?? "/smart-home",
    meta: index7XgwCHpLSRMeta || {},
    alias: index7XgwCHpLSRMeta?.alias || [],
    redirect: index7XgwCHpLSRMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/smart-home/index.vue").then(m => m.default || m)
  },
  {
    name: index7XgwCHpLSRMeta?.name ?? "smart-home___it-IT",
    path: index7XgwCHpLSRMeta?.path ?? "/it-IT/smart-home",
    meta: index7XgwCHpLSRMeta || {},
    alias: index7XgwCHpLSRMeta?.alias || [],
    redirect: index7XgwCHpLSRMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/smart-home/index.vue").then(m => m.default || m)
  },
  {
    name: indexcF9BcfoxGGMeta?.name ?? "support___en-US",
    path: indexcF9BcfoxGGMeta?.path ?? "/en-US/support",
    meta: indexcF9BcfoxGGMeta || {},
    alias: indexcF9BcfoxGGMeta?.alias || [],
    redirect: indexcF9BcfoxGGMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexcF9BcfoxGGMeta?.name ?? "support___fr-FR",
    path: indexcF9BcfoxGGMeta?.path ?? "/support",
    meta: indexcF9BcfoxGGMeta || {},
    alias: indexcF9BcfoxGGMeta?.alias || [],
    redirect: indexcF9BcfoxGGMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexcF9BcfoxGGMeta?.name ?? "support___it-IT",
    path: indexcF9BcfoxGGMeta?.path ?? "/it-IT/support",
    meta: indexcF9BcfoxGGMeta || {},
    alias: indexcF9BcfoxGGMeta?.alias || [],
    redirect: indexcF9BcfoxGGMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/support/index.vue").then(m => m.default || m)
  }
]